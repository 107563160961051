/** @jsx jsx */
import { jsx, Container, Box, Link } from "theme-ui";
import { keyframes } from "@emotion/core";
import Logo from "components/logo";
import LogoDark from "assets/fapshi.svg";
import MobileDrawer from "./mobile-drawer";
import menuItems from "./header.data";
import { colors } from "../../style_dependencies/variables";
import ButtonLink from "../button-link";
import FeaturesMenu from "./features-menu";
import DocsMenu from "./docs-menu";
import NotificationBar from "./notification";

export default function Header({ className }) {
  const btnData = {
    btnName: "Sign In",
    btnURL: "https://dashboard.fapshi.com",
    btnType: "coloredBtn",
  };

  return (
    <header sx={styles.header} className={className} id="header">
      <NotificationBar />
      <Container sx={styles.container}>
        <Box sx={styles.headerBox}>
          <Logo src={LogoDark} />
        </Box>
        <Box as="nav" sx={styles.nav}>
          {typeof window !== "undefined" && window.location.pathname !== "/" && (
            <Link href="/" sx={styles.nav.link}>
              Home{" "}
            </Link>
          )}
          <FeaturesMenu />
          <DocsMenu />

          {menuItems.map(({ path, label }, i) => (
            <Link href={path} key={i} sx={styles.nav.link}>
              {label}
            </Link>
          ))}
        </Box>
        <Box sx={styles.headerBtn}>
          <ButtonLink data={btnData} />
        </Box>
        <Box sx={styles.mobileDrawer}>
          <MobileDrawer />
        </Box>
      </Container>
    </header>
  );
}

const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }
  to {
    position: absolute;
    opacity: 1;
    transition: all 0.4s ease;
  }
`;

const styles = {
  header: {
    color: colors.headingColor,
    fontWeight: "500",
    py: 4,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "transparent",
    transition: "all 0.5s ease",
    animation: `${positionAnim} 0.4s ease`,

    "nav > a": {
      color: colors.headingColor,
      textDecoration: "none",

      "&:hover": {
        color: colors.secondaryColor,
      },
      "&.active": {
        color: colors.secondaryColor,
      },
    },

    "&.sticky": {
      position: "fixed",
      backgroundColor: "background",
      color: "#000000",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.06)",
      py: 3,
    },
  },

  container: {
    display: "flex",
    alignItems: "center",
  },
  nav: {
    display: "none",
    "@media screen and (min-width: 1024px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "60%",
    },
    link: {
      fontSize: "17px",
      cursor: "pointer",
      mr: "32px",
      lineHeight: "1.5",
      "&.active": {
        color: "secondary",
      },
    },
  },

  headerBtn: {
    display: "none",
    "@media screen and (min-width: 1024px)": {
      display: "flex",
      justifyContent: "end",
      "-webkit-justify-content": "flex-end",
      width: "20%",
    },
  },

  headerBox: {
    width: "50%",
    "@media screen and (min-width: 1024px)": {
      width: "20%",
    },
  },

  mobileDrawer: {
    display: "flex",
    width: "50%",
    justifyContent: "end",
    "-webkit-justify-content": "flex-end",

    "@media screen and (min-width: 1024px)": {
      display: "none",
    },
  },
};
