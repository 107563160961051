import { Link } from "theme-ui";
import * as React from "react";

import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { colors } from "style_dependencies/variables";

import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export default function DocsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStarted = [
    {
      id: 1,
      title: "Integrate payments online",
      link: "https://documentation.fapshi.com#api",
    },
    {
      id: 2,
      title: "Create an account",
      link: "https://dashboard.fapshi.com/register",
    },
    {
      id: 3,
      title: "Create a service",
      link: "https://documentation.fapshi.com#create-a-service",
    },
    {
      id: 4,
      title: "WordPress Plugin",
      link: "https://github.com/Fapshi/Plugins",
    },
  ];

  const guides = [
    {
      id: 1,
      title: "How to Payout",
      link: "https://documentation.fapshi.com#payouts",
    },
    {
      id: 2,
      title: "Set a Pin for Payouts",
      link: "https://documentation.fapshi.com#set-your-pin",
    },
    {
      id: 3,
      title: "Activate your account",
      link: "https://documentation.fapshi.com#activate-your-account",
    },
    {
      id: 4,
      title: "Make Bulk Payments",
      link: "https://documentation.fapshi.com#bulk-payments",
    },
  ];

  const FooterData = [
    {
      id: 1,
      icon: <ReorderOutlinedIcon sx={styles.icon} fontSize="small" />,
      title: "Full Reference",
      link: "https://documentation.fapshi.com",
    },
    {
      id: 2,
      icon: <ShowChartOutlinedIcon sx={styles.icon} fontSize="small" />,
      title: "How to use the API",
      link: "https://documentation.fapshi.com/dev-tools.html",
    },
    {
      id: 3,
      icon: <SupportAgentOutlinedIcon sx={styles.icon} fontSize="small" />,
      title: "Support",
      link: "https://support.fapshi.com",
    },
    {
      id: 4,
      icon: <QuestionAnswerOutlinedIcon sx={styles.icon} fontSize="small" />,
      title: "SDKs",
      link: "https://github.com/Fapshi/SDKs",
    },
  ];

  return (
    <React.Fragment>
      <Box>
        <Typography
          onMouseEnter={handleClick}
          onClick={handleClick}
          sx={styles.menuHeading}
        >
          Developers
        </Typography>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
              mt: 1.5,
              width: 620,
              pt: 2,
              pb: 0,
              borderRadius: "10px",
              fontFamily: "'Hind', sans-serif",
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: "48%",
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <Grid container>
            <a
              href="https://documentation.fapshi.com"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <ListItem
                sx={{ mb: 2, px: 5, alignItems: "start" }}
                onClick={handleClose}
              >
                <LibraryBooksOutlinedIcon
                  sx={styles.docIcon}
                  fontSize="small"
                />
                <ListItemText
                  sx={{ ml: 1, mt: "-4px" }}
                  primary={
                    <Typography sx={styles.heading} style={{ opacity: 1 }}>
                      Documentation
                    </Typography>
                  }
                  secondary={
                    <Typography sx={styles.text}>
                      Start using Fapshi's services and tools
                    </Typography>
                  }
                />
              </ListItem>
            </a>
            <Grid container sx={styles.body} spacing={2}>
              <Grid item xs={6}>
                <Typography sx={styles.heading}>GET STARTED</Typography>
                {getStarted.map((listItem) => (
                  <a
                    href={listItem.link}
                    style={{ textDecoration: "none" }}
                    key={listItem.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemText
                      sx={{ mt: "8px" }}
                      onClick={handleClose}
                      primary={
                        <Typography sx={styles.text}>
                          {listItem.title}
                        </Typography>
                      }
                    />
                  </a>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  mb: 1,
                }}
              >
                <Typography sx={styles.heading}>GUIDES</Typography>
                {guides.map((listItem) => (
                  <a
                    href={listItem.link}
                    style={{ textDecoration: "none" }}
                    key={listItem.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemText
                      onClick={handleClose}
                      sx={{ mt: "8px" }}
                      primary={
                        <Typography sx={styles.text}>
                          {listItem.title}
                        </Typography>
                      }
                    />
                  </a>
                ))}
              </Grid>
            </Grid>
            <Grid container sx={styles.footer}>
              {FooterData.map((listItem) => (
                <Grid item xs={6} key={listItem.id}>
                  {listItem.link.indexOf("/", 0) === 0 ? (
                    <Link
                      href={listItem.link}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem onClick={handleClose}>
                        {listItem.icon}
                        <ListItemText
                          sx={{ ml: 1, mt: "6px" }}
                          primary={
                            <Typography sx={styles.text}>
                              {listItem.title}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Link>
                  ) : (
                    <a
                      href={listItem.link}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ListItem onClick={handleClose}>
                        {listItem.icon}
                        <ListItemText
                          sx={{ ml: 1, mt: "6px" }}
                          primary={
                            <Typography sx={styles.text}>
                              {listItem.title}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </a>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Menu>
      </Box>
    </React.Fragment>
  );
}

const styles = {
  menuHeading: {
    mx: "32px",
    fontFamily: "'Hind', sans-serif",
    fontSize: "17px",
    fontWeight: "500 !important",
    cursor: "context-menu",
    color: colors.btnColor,

    "&:hover": {
      color: colors.secondaryColor,
    },
    "&.active": {
      color: colors.secondaryColor,
    },
  },
  link: {
    textDecoration: "none",
  },
  menuLink: {
    textDecoration: "none",
    p: {
      mt: "8px",
    },
  },
  icon: {
    color: colors.btnColor,
    backgroundColor: colors.mainColor,
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
    borderRadius: "50px",
    width: "30px",
    height: "30px",
    padding: "6px",
  },
  docIcon: {
    color: colors.btnColor,
    backgroundColor: "white",
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 1px 5px 0px",
    borderRadius: "50px",
    width: "30px",
    height: "30px",
    padding: "6px",
  },
  heading: {
    fontFamily: "'Hind', sans-serif",
    fontWeight: 600,
    fontSize: "17px",
    color: colors.btnColor,
    opacity: 0.8,
  },
  text: {
    fontFamily: "'Hind', sans-serif",
    fontSize: "15px",
    color: colors.btnColor,
    opacity: 0.8,
  },
  body: {
    pl: 8.5,
    pr: 3,
  },
  footer: {
    backgroundColor: colors.mainColor,
    borderRadius: "10px",
    m: "5px",
    mb: "-4px",
    mt: 3,
    py: 2,
    pl: 2,
  },
};
