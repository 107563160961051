export default [
  {
    path: "/pricing",
    label: "Pricing",
  },
  {
    path: "/contact",
    label: "Contact Us",
  },
  {
    path: "https://support.fapshi.com",
    label: "Help & Support",
  },
];
