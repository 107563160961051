import { Box } from "theme-ui";
import React from "react";
import { colors } from "../../style_dependencies/variables";

export default function NotificationBar() {
  return (
    <a
      href="https://dashboard.fapshi.com"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none !important" }}
    >
      <Box sx={styles.launchNotification}>
        {/* <span
          role="img"
          aria-labelledby="jsx-a11y/accessible-emoji"
          style={styles.launchNotification.launchIcon}
        >
          &#127881;
        </span> */}
        <p style={styles.launchNotification.launchText}>
          You can now do bulk payments on Fapshi. Login and see the magic!
        </p>
      </Box>
    </a>
  );
}

const styles = {
  launchNotification: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.btnColor,
    px: "1rem",
    pt: "0.8rem",
    pb: "0.3rem",
    mt: "-1.5rem",
    mb: "1rem",
    color: "white",
    textAlign: "center",
    lineHeight: 1.5,

    launchIcon: {
      margin: 0,
    },

    launchText: {
      margin: 0,
      marginLeft: "5px",
      color: "white",
    },
  },
};
