import * as React from "react";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { colors } from "style_dependencies/variables";
import Avatar from "@mui/material/Avatar";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

const styles = {
  menuHeading: {
    fontFamily: "'Hind', sans-serif",
    fontSize: "17px",
    fontWeight: "500 !important",
    cursor: "context-menu",
    color: colors.headingColor,

    "&:hover": {
      color: colors.secondaryColor,
    },
    "&.active": {
      color: colors.secondaryColor,
    },
  },
  link: {
    textDecoration: "none !important",
    li: {
      alignItems: "start",
    },
  },
  avatar: {
    bgcolor: "#fff",
    height: 35,
    width: 35,
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 1px 5px 0px",
  },
  icon: {
    color: colors.btnColor,
  },
  textHeading: {
    fontFamily: "'Hind', sans-serif",
    fontSize: "17px",
    fontWeight: 600,
    color: colors.btnColor,
    mt: "-6px",
  },
  text: {
    fontFamily: "'Hind', sans-serif",
    fontSize: "15px",
    opacity: 0.8,
    color: colors.btnColor,
  },
};

const data = [
  {
    id: 6,
    icon: <PaymentsOutlinedIcon sx={{ color: "#003caf" }} fontSize="small" />,
    title: "Payment Aggregator",
    desc: "Integrate payments on your website or app through our APIs",
    link: "https://documentation.fapshi.com#api",
  },
  {
    id: 3,
    icon: (
      <ForwardToInboxOutlinedIcon sx={{ color: "#00d5e2" }} fontSize="small" />
    ),
    title: "Payment Links",
    desc: "Collect payments through a link without stress",
    link: "https://documentation.fapshi.com#payment-links",
  },
  {
    id: 4,
    icon: (
      <DescriptionOutlinedIcon sx={{ color: "#00c013" }} fontSize="small" />
    ),
    title: "Invoice",
    desc: "Create and send invoices to your clients via email or a link",
    link: "https://documentation.fapshi.com#invoicing",
  },
  {
    id: 1,
    icon: (
      <ShoppingCartCheckoutOutlinedIcon
        sx={{ color: "#ff7a00" }}
        fontSize="small"
      />
    ),
    title: "Products",
    desc: "Create products and share their links to get paid",
    link: "https://documentation.fapshi.com#products",
  },
  {
    id: 2,
    icon: <StoreOutlinedIcon sx={{ color: "#f94a8b" }} fontSize="small" />,
    title: "Store",
    desc: "Sell your products online with Fapshi Stores",
    link: "https://documentation.fapshi.com#stores",
  },
];

export default function FeaturesMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Typography
        onMouseEnter={handleClick}
        onClick={handleClick}
        sx={styles.menuHeading}
      >
        Features
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
            mt: 1.5,
            width: 600,
            px: 3,
            py: 2,
            borderRadius: "10px",
            fontFamily: "'Hind', sans-serif",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: "48%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Grid container>
          {data.map((listItem) => (
            <a
              href={listItem.link}
              style={{ textDecoration: "none" }}
              key={listItem.id}
              target="_blank"
              rel="noreferrer"
            >
              <ListItem onClick={handleClose}>
                <ListItemAvatar>
                  <Avatar sx={styles.avatar}>{listItem.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography sx={styles.textHeading}>
                      {listItem.title}
                    </Typography>
                  }
                  secondary={
                    <Typography sx={styles.text}>{listItem.desc}</Typography>
                  }
                />
              </ListItem>
            </a>
          ))}
        </Grid>
      </Menu>
    </React.Fragment>
  );
}
