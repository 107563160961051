/** @jsx jsx */
import { jsx, Box, Text, Container, Heading, Link } from "theme-ui";
import { social, menuItems, menuLinks } from "./footer.data";
import { colors } from "../../style_dependencies/variables";
import ButtonLink from "../button-link";

export default function Footer() {
  const btnData = {
    btnName: "Know more",
    btnURL: "https://engr.fapshi.com",
    btnType: "simpleBtn",
  };

  return (
    <footer sx={styles.footer}>
      <Box sx={styles.footer.content}>
        <Container sx={styles.footer.container}>
          <Box sx={styles.footer.footerTopArea}>
            {menuItems.map(({ header, items }, i) => (
              <Box
                sx={i === 0 ? styles.footer.docs : styles.footer.menus}
                key={i}
              >
                <Heading sx={styles.footer.heading}>{header}</Heading>
                <nav>
                  {items.map(({ path, label }, i) =>
                    path.indexOf("/", 0) === 0 ? (
                      <Link href={path} key={i} sx={styles.footer.link}>
                        {label}
                      </Link>
                    ) : (
                      <a
                        href={path}
                        key={i}
                        sx={styles.footer.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {label}
                      </a>
                    )
                  )}
                </nav>
              </Box>
            ))}
          </Box>
        </Container>
        <Box
          sx={{
            variant: "layout.footer",
            paddingTop: "2rem",
          }}
        >
          <Container sx={styles.footerMenu}>
            <Box sx={styles.footerMenu.contact}>
              <Text as="h3">Contact</Text>
              <Text as="p" sx={styles.footerMenu.link}>
                hello@fapshi.com
              </Text>
              <Box sx={styles.social}>
                {social.map(({ path, icon }, i) => (
                  <Box as="span" key={i} sx={styles.social.icon}>
                    <a href={path} target="_blank" rel="noreferrer">
                      {icon}
                    </a>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={styles.footerMenu.location}>
              <Text as="h3">Buea</Text>
              <Text as="p">Central Market Road,</Text>
              <Text as="p">Buea - South West Region</Text>
              <Text as="p">Cameroon</Text>
              <Text as="p" sx={styles.footerMenu.link}>
                +237 673 669 111
              </Text>
            </Box>

            <Box sx={styles.footerMenu.location}>
              <Text as="h3">Middletown</Text>
              <Text as="p">Suite 201,</Text>
              <Text as="p">651 N Broad St,</Text>
              <Text as="p">DE, 19709</Text>
              <Text as="p">United States</Text>
            </Box>

            <Box sx={styles.footerMenu.engr}>
              <Text as="h3">Engineering @Fapshi</Text>
              <Text as="p">
                Hire software developers from Fapshi's network of engineers to
                work on your projects.
              </Text>
              <Box sx={styles.footerMenu.btnBox}>
                <ButtonLink data={btnData} />
              </Box>
            </Box>
          </Container>

          <Container
            sx={{
              variant: "layout.toolbar",
              justifyContent: ["center", null, null, "space-between"],
              flexDirection: ["column", null, null, null, "row"],
              paddingTop: [16, 24],
              paddingBottom: [24, 32],
            }}
          >
            <Box sx={styles.left}>
              <Text as="p">&copy; {new Date().getFullYear()} Fapshi Inc.</Text>
            </Box>
            <Box sx={styles.right}>
              {menuLinks.map(({ path, label, name }, i) =>
                name ? (
                  <a href={path} target="_blank" rel="noreferrer" key={i}>
                    {label}
                  </a>
                ) : (
                  <a href={path} rel="noreferrer" key={i}>
                    {label}
                  </a>
                )
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </footer>
  );
}

const styles = {
  footerMenu: {
    borderTop: "1px solid",
    borderTopColor: "border_color",
    pt: ["2rem", null, null, "4rem"],
    pb: ["1rem", null, null, "2rem"],
    display: "flex",
    flexDirection: ["column", null, "row"],
    alignItems: "start",
    justifyContent: ["space-between", "center", "space-between"],

    link: {
      color: colors.blueColor,
    },

    contact: {
      pr: [0, 0, "1rem"],
    },

    location: {
      pr: [0, 0, "1rem"],
      mt: ["1rem", null, 0],
    },

    engr: {
      maxWidth: ["unset", "unset", "31%"],
      pl: [0, 0, "1rem"],
      mt: ["1rem", null, 0],
    },

    btnBox: {
      mt: "1rem",

      a: {
        textDecoration: "none",
      },

      button: {
        boxShadow: "rgba(100, 100, 111, 0.1) 0px 1px 5px 0px",
      },
    },
  },
  left: {
    display: "flex",
    flexDirection: ["column", null, "row"],
    alignItems: "center",
    p: {
      fontSize: [0, 1],
      color: "black",
      opacity: 0.6,
      mt: ["10px", null, "0"],
    },
  },
  right: {
    display: ["none", null, null, null, "flex"],
    fontSize: 2,
    alignItems: "center",
    color: colors.btnColor,
    a: {
      cursor: "pointer",
      transition: "500ms",
      textDecoration: "none",
      color: "black",
      "&:hover": {
        color: "primary",
      },
    },
    "a+a": {
      marginLeft: "30px",
    },
  },

  social: {
    icon: {
      fontSize: 16,
      mr: "15px",
      transition: "all 0.25s",
      cursor: "pointer",
      ":last-child": {
        mr: "0",
      },
      a: {
        color: "text",
      },
    },
  },

  footer: {
    backgroundColor: colors.lightBg,
    clipPath: "polygon(0 9%, 100% 0, 100% 100%, 0 100%)",

    "@media screen and (max-width: 767px)": {
      clipPath: "polygon(0 4%, 100% 0, 100% 100%, 0 100%)",
    },

    container: {
      width: "100%",
      alignItems: "stretch",
    },
    footerTopArea: {
      display: "flex",
      flexWrap: "wrap",
      pt: ["100px", null, null, "150px"],
      px: [0, null, null, null, 4],
    },
    menus: {
      width: ["50%", "23%", null, null, "23%"],
      display: "flex",
      flexDirection: "column",
      pr: "1rem",
      mb: ["40px", null, null, "60px"],
    },
    docs: {
      width: ["50%", "31%", null, null, "31%"],
      display: "flex",
      flexDirection: "column",
      pr: "1rem",
      mb: ["40px", null, null, "60px"],
    },

    heading: {
      fontSize: "18px",
      color: "text_secondary",
      fontWeight: "500",
      mb: [1, 2, 3, null, 4],
      lineHeight: "1.35",
    },

    link: {
      fontSize: "16px",
      color: "text",
      fontWeight: 400,
      mb: 2,
      cursor: "pointer",
      transition: "all 0.35s",
      display: "block",
      textDecoration: "none",
      lineHeight: [1.5, null, null, 1.6, 1.8],
      ":hover": {
        color: "primary",
      },
      ":last-child": {
        mb: "0px",
      },
    },
  },
};
