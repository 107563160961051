import React, { useState } from "react";
import { IoMdMenu, IoMdClose } from "react-icons/io";
import { Link } from "theme-ui";
import {
  Menu,
  Typography,
  Grid,
  Divider,
  Box,
  ListItem,
  ListItemText,
} from "@mui/material";

import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";

import { colors } from "style_dependencies/variables";
import ButtonLink from "../button-link";

const styles = {
  link: {
    textDecoration: "none !important",
  },
  icon: {
    color: "#5f2792",
  },
  heading: {
    fontFamily: "Hind",
    fontWeight: "bold",
    fontSize: "16px",
    color: colors.btnColor,
    ml: 2,
    mb: 1,
  },
  text: {
    fontFamily: "Hind",
    fontSize: "14px",
    color: colors.btnColor,
  },
  footer: {
    backgroundColor: colors.mainColor,
    borderRadius: "10px",
    m: "5px",
    mb: "-4px",
    mt: 5,
    py: 2,
    px: 2,
    width: "unset",
  },

  headerBtn: {
    m: "0 auto",
    mt: "10px",
  },

  textHeading: {
    fontFamily: "Hind",
    fontSize: "16px",
    color: colors.btnColor,
  },

  gridContainer: {
    px: 2,
    mb: 2,
  },
  handler: {
    display: "flex",
    borderRadius: "1rem",
    padding: "0.1rem 0.7rem",
    backgroundColor: colors.btnColor,
    color: "white",
    cursor: "pointer",
    "@media screen and (min-width: 1024px)": {
      display: "none",
    },
  },
};

const featuresData = [
  {
    id: 1,
    icon: <PaymentsOutlinedIcon sx={{ color: "#5f2792" }} fontSize="small" />,
    title: "Integrate Payments",
    link: "https://documentation.fapshi.com#api",
  },
  {
    id: 2,
    icon: (
      <ForwardToInboxOutlinedIcon sx={{ color: "#9966ff" }} fontSize="small" />
    ),
    title: "Payment Links",
    link: "https://documentation.fapshi.com#payment-links",
  },
  {
    id: 3,
    icon: (
      <CreditScoreOutlinedIcon sx={{ color: "#00d5e2" }} fontSize="small" />
    ),
    title: "Payouts",
    link: "https://documentation.fapshi.com#payouts",
  },
  {
    id: 4,
    icon: (
      <DescriptionOutlinedIcon sx={{ color: "#00c013" }} fontSize="small" />
    ),
    title: "Invoice",
    link: "https://documentation.fapshi.com#invoicing",
  },
  {
    id: 5,
    icon: (
      <ShoppingCartCheckoutOutlinedIcon
        sx={{ color: "#ff7a00" }}
        fontSize="small"
      />
    ),
    title: "Products",
    link: "https://documentation.fapshi.com#products",
  },
  {
    id: 6,
    icon: <StoreOutlinedIcon sx={{ color: "#f94a8b" }} fontSize="small" />,
    title: "Store",
    link: "https://documentation.fapshi.com#stores",
  },
];

const data = [
  {
    id: 1,
    icon: <AccountBoxOutlinedIcon sx={{ color: "#d83c8e" }} fontSize="small" />,
    title: "Activate your account",
    link: "https://documentation.fapshi.com#activate-your-account",
  },
  {
    id: 2,
    icon: (
      <DescriptionOutlinedIcon sx={{ color: "#0843b2" }} fontSize="small" />
    ),
    title: "How to use the API",
    link: "https://documentation.fapshi.com/dev-tools",
  },
  {
    id: 3,
    icon: <StorefrontOutlinedIcon sx={{ color: "#ffcb07" }} fontSize="small" />,
    title: "Create a service",
    link: "https://documentation.fapshi.com#create-a-service",
  },
  {
    id: 4,
    icon: (
      <ForwardToInboxOutlinedIcon sx={{ color: "#ff7a00" }} fontSize="small" />
    ),
    title: "How to create payment links",
    link: "https://youtu.be/g9ZohxRmFAU",
  },
  {
    id: 5,
    icon: <PaymentsOutlinedIcon sx={{ color: "#5f2792" }} fontSize="small" />,
    title: "How to withdraw (payout) on Fapshi",
    link: "https://youtu.be/aHI1FPoRG28",
  },
  {
    id: 6,
    icon: <StoreOutlinedIcon sx={{ color: "#00c013" }} fontSize="small" />,
    title: "Own an online store",
    link: "https://documentation.fapshi.com#create-a-store",
  },
];

const FooterData = [
  {
    id: 1,
    icon: <HomeOutlinedIcon sx={{ color: "#00d5e2" }} fontSize="small" />,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    icon: <SellOutlinedIcon sx={{ color: "#00c013" }} fontSize="small" />,
    title: "Pricing",
    link: "/pricing",
  },
  {
    id: 3,
    icon: <ReorderOutlinedIcon sx={{ color: "#ff7a00" }} fontSize="small" />,
    title: "Guides",
    link: "https://youtube.com/playlist?list=PLN5_sHsQUOOut0LtzYODxz_p7yw6neWgP",
  },
  {
    id: 4,
    icon: (
      <SupportAgentOutlinedIcon sx={{ color: "#0843b2" }} fontSize="small" />
    ),
    title: "Support",
    link: "https://support.fapshi.com",
  },
  {
    id: 5,
    icon: (
      <QuestionAnswerOutlinedIcon sx={{ color: "#d83c8e" }} fontSize="small" />
    ),
    title: "FAQs",
    link: "https://documentation.fapshi.com#faqs",
  },
  {
    id: 6,
    icon: (
      <QuestionAnswerOutlinedIcon sx={{ color: "#5f2792" }} fontSize="small" />
    ),
    title: "Contact us",
    link: "/contact",
  },
  {
    id: 7,
    icon: <PolicyOutlinedIcon sx={{ color: "#ffbb00" }} fontSize="small" />,
    title: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    id: 8,
    icon: <GavelOutlinedIcon sx={{ color: "#003caf" }} fontSize="small" />,
    title: "Terms & Conditions",
    link: "/terms-and-conditions",
  },
];

const btnData = {
  btnName: "Sign Up",
  btnURL: "https://dashboard.fapshi.com/register",
  btnType: "coloredBtn",
};

const MobileDrawer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={styles.handler} onClick={handleClick}>
        <IoMdMenu size="26px" />
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
            pt: 2,
            pb: 0,
            borderRadius: "10px",
            fontFamily: "Hind",

            "@media screen and (min-width: 1024px)": {
              display: "none",
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Grid container sx={styles.gridContainer}>
          <Grid item xs={6}>
            <Typography sx={styles.heading}>FEATURES</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              "-webkit-justify-content": "flex-end",
            }}
            onClick={handleClose}
          >
            <IoMdClose size="26px" />
          </Grid>
          {featuresData.map((listItem) => (
            <Grid item xs={6} sm={4} md={3} key={listItem.id}>
              <a
                href={listItem.link}
                style={{ textDecoration: "none" }}
                key={listItem.id}
                target="_blank"
                rel="noreferrer"
              >
                <ListItem sx={{ alignItems: "start" }} onClick={handleClose}>
                  {listItem.icon}{" "}
                  <ListItemText
                    sx={{ ml: 1, mt: "-2px" }}
                    primary={
                      <Typography sx={styles.textHeading}>
                        {listItem.title}
                      </Typography>
                    }
                  />
                </ListItem>
              </a>
            </Grid>
          ))}
        </Grid>

        <Divider variant="middle" sx={{ borderStyle: "dashed" }} />

        <Grid container sx={styles.gridContainer}>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography sx={styles.heading}>GETTING STARTED</Typography>
          </Grid>
          {data.map((listItem) => (
            <Grid item xs={6} sm={4} md={3} key={listItem.id}>
              <a
                href={listItem.link}
                style={{ textDecoration: "none" }}
                key={listItem.id}
                target="_blank"
                rel="noreferrer"
              >
                <ListItem sx={{ alignItems: "start" }} onClick={handleClose}>
                  {listItem.icon}
                  <ListItemText
                    sx={{ ml: 1, mt: "-1px" }}
                    primary={
                      <Typography sx={styles.textHeading}>
                        {listItem.title}
                      </Typography>
                    }
                  />
                </ListItem>
              </a>
            </Grid>
          ))}
        </Grid>

        <Divider variant="middle" sx={{ borderStyle: "dashed", mb: 3 }} />

        <Grid container sx={styles.gridContainer}>
          {FooterData.map((listItem) => (
            <Grid item xs={6} sm={4} md={3} key={listItem.id}>
              {listItem.link.indexOf("/", 0) === 0 ? (
                <Link
                  href={listItem.link}
                  style={{ textDecoration: "none" }}
                  key={listItem.id}
                >
                  <ListItem sx={{ alignItems: "start" }} onClick={handleClose}>
                    {listItem.icon}
                    <ListItemText
                      sx={{ ml: 1, mt: "-1px" }}
                      primary={
                        <Typography sx={styles.textHeading}>
                          {listItem.title}
                        </Typography>
                      }
                      secondary={
                        <Typography sx={styles.text}>
                          {listItem.desc}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Link>
              ) : (
                <a
                  href={listItem.link}
                  style={{ textDecoration: "none" }}
                  key={listItem.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ListItem sx={{ alignItems: "start" }} onClick={handleClose}>
                    {listItem.icon}
                    <ListItemText
                      sx={{ ml: 1, mt: "-1px" }}
                      primary={
                        <Typography sx={styles.textHeading}>
                          {listItem.title}
                        </Typography>
                      }
                      secondary={
                        <Typography sx={styles.text}>
                          {listItem.desc}
                        </Typography>
                      }
                    />
                  </ListItem>
                </a>
              )}
            </Grid>
          ))}
        </Grid>

        <Grid container sx={styles.footer}>
          <Box sx={styles.headerBtn}>
            <ButtonLink data={btnData} />
          </Box>
        </Grid>
      </Menu>
    </React.Fragment>
  );
};

export default MobileDrawer;
