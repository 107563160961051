/** @jsx jsx */
import { jsx } from "@theme-ui/core";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

export const menuLinks = [
  {
    path: "/pricing",
    label: "Pricing",
  },
  {
    path: "https://support.fapshi.com",
    label: "Support",
  },
  {
    path: "/contact",
    label: "Contact Us",
  },
  {
    path: "https://documentation.fapshi.com/dev-tools.html",
    label: "API Docs",
  },
  {
    path: "https://documentation.fapshi.com",
    label: "Documentation",
    name: "docs",
  },
];

export const social = [
  {
    path: "https://www.linkedin.com/company/fapshi-pay/",
    icon: <FaLinkedinIn />,
  },
  {
    path: "https://www.youtube.com/@fapshi",
    icon: <FaYoutube />,
  },
  {
    path: "https://www.facebook.com/fapshi/",
    icon: <FaFacebookF />,
  },
  {
    path: "https://twitter.com/fapshi",
    icon: <FaTwitter />,
  },
  {
    path: "https://www.instagram.com/fapshihq/",
    icon: <FaInstagram />,
  },
];

export const menuItems = [
  {
    header: "Documentation",
    items: [
      {
        path: "https://documentation.fapshi.com#create-an-account",
        label: "Create an account",
      },
      {
        path: "https://documentation.fapshi.com/#api",
        label: "Integrate Payments Online",
      },
      {
        path: "https://documentation.fapshi.com#bulk-payments",
        label: "Make Bulk Payments",
      },
      {
        path: "https://documentation.fapshi.com#payouts",
        label: "How to Payout",
      },
      {
        path: "https://youtu.be/g9ZohxRmFAU",
        label: "Create a Payment Link",
      },
      {
        path: "https://documentation.fapshi.com#pricing-calculator",
        label: "Using the pricing calculator",
      },
    ],
  },
  {
    header: "Features",
    items: [
      {
        path: "https://documentation.fapshi.com#api",
        label: "Payment Aggregator",
      },
      {
        path: "https://documentation.fapshi.com#payment-links",
        label: "Payment Links",
      },
      {
        path: "https://documentation.fapshi.com#payouts",
        label: "Payouts",
      },

      {
        path: "https://documentation.fapshi.com#invoicing",
        label: "Invoicing",
      },
      {
        path: "https://documentation.fapshi.com#products",
        label: "Products",
      },
      {
        path: "https://github.com/Fapshi/Plugins",
        label: "WordPress Plugin",
      },
    ],
  },
  {
    header: "Resources",
    items: [
      {
        path: "https://documentation.fapshi.com/dev-tools.html",
        label: "API Docs",
      },
      {
        path: "https://youtube.com/playlist?list=PLN5_sHsQUOOut0LtzYODxz_p7yw6neWgP",
        label: "Guides",
      },
      {
        path: "https://documentation.fapshi.com/aml-policy.html",
        label: "AML Policy",
      },
      {
        path: "/pricing",
        label: "Pricing",
      },
      {
        path: "/privacy-policy",
        label: "Privacy Policy",
      },
      {
        path: "/terms-and-conditions",
        label: "Terms & Conditions",
      },
    ],
  },
  {
    header: "Help",
    items: [
      {
        path: "https://documentation.fapshi.com#faqs",
        label: "FAQs",
      },
      {
        path: "https://support.fapshi.com",
        label: "Help & Support",
      },
      {
        path: "/contact",
        label: "Contact us",
      },
    ],
  },
];
